.App {
  text-align: center;
  overscroll-behavior: contain;
}

body {
  background-color: #161616;
  overscroll-behavior: contain;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.title {
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  size: 1rem;
  font-weight: 1000;
}

.subtitle {
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: 1000;
}

.text {
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  size: 1rem;
  font-weight: 1000;
}

.button-text {
  color: rgb(0, 0, 0);
  text-transform: uppercase;
  size: 1rem;
  font-weight: 1000;
}

.green {
  background-color: #00ff00;
}

.yellow {
  background-color: #fffb00;
}

.red {
  background-color: #ff0000;
}

.grey {
  background-color: #666666;
}

.white {
  background-color: #ffffff;
}

.section-event {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 99;
}

.noselect {
  user-select: none;
}

.rounded {
  border-radius: 10px;
}
